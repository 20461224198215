import * as React from 'react';
import '../../css/homepage.scss';
import { graphql } from 'gatsby';
import Markdown from '../../components/Markdown';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import heroImg from '../../images/hero.png';
import heroMobileImg from '../../images/hero-mobile@2x.png';

export const query = graphql`
  {
    strapiHomepage(
      localizations: {
        data: { elemMatch: { attributes: { locale: { eq: "en" } } } }
      }
    ) {
      localizations {
        data {
          attributes {
            content1
            content2
            content3
            footer
            hero
            title1
            title2
            title3
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  return (
    <Layout locale="en">
      <Seo title="Reforms for the better future" locale="en" />
      <div className="homepage__hero">
        <h1>
          <span className="homepage__hero-highlight">Reform Institute —</span>
          <br />
          {data.strapiHomepage.localizations.data[0].attributes.hero}
        </h1>
        <img src={heroImg} alt="" className="homepage__hero-img" />
        <img src={heroMobileImg} alt="" className="homepage__hero-mobile-img" />
      </div>
      <div className="homepage">
        <div className="homepage__block">
          <h2>{data.strapiHomepage.localizations.data[0].attributes.title1}</h2>
          <Markdown>
            {data.strapiHomepage.localizations.data[0].attributes.content1}
          </Markdown>
        </div>
        <div className="homepage__block">
          <h2>{data.strapiHomepage.localizations.data[0].attributes.title2}</h2>
          <Markdown>
            {data.strapiHomepage.localizations.data[0].attributes.content2}
          </Markdown>
        </div>
        <div className="homepage__block">
          <h2>{data.strapiHomepage.localizations.data[0].attributes.title3}</h2>
          <Markdown>
            {data.strapiHomepage.localizations.data[0].attributes.content3}
          </Markdown>
        </div>
        <div className="homepage__footer">
          <h2 className="no-square">
            <Markdown disallowedElements={['p']} unwrapDisallowed={true}>
              {data.strapiHomepage.localizations.data[0].attributes.footer}
            </Markdown>
          </h2>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
